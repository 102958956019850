import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

function getAllMovement (movementInfo) {
  return Array.prototype.map.call(movementInfo, function (item) { return item.lori_challan_no }).join(',')
}

function getAllShip (ships) {
  return Array.prototype.map.call(ships, function (item) {
    if (i18n.locale === 'bn') {
      return item.ship_name_bn
    } else {
      return item.ship_name
    }
  }).join(',')
}
function calculateWatehouse (agent) {
    return agent.map(r => r.warehouse_total).reduce((a, c) => {
     a = a ? parseInt(a) : 0
     c = c ? parseInt(c) : 0
     return a + c
   })
 }
function calculateTotal (report) {
    return report.map(r => r.total_allocated_amount).reduce((a, c) => {
     a = a ? parseInt(a) : 0
     c = c ? parseInt(c) : 0
     return a + c
   })
}
function calculateTotalAll (data) {
  let total = 0
  data.forEach((element, key) => {
    element.summary_ships_array.forEach((element1, key1) => {
      element1.movement_info.forEach((element2, key2) => {
        total += element2.total_allocated_amount
      })
    })
  })
  return total
}
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, dataArray, vm, office, reportDate, search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          {
            columns: [
              { width: '*', text: '' },
              { width: '*', text: '' },
              { width: '*', text: vm.$t('fertilizerReport.krishi_bhaban') },
              { width: '*', text: '' },
              { width: '*', text: '' }
            ],
            style: 'krishi'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]

        if (reportHeadData.projectName) {
          pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }

        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        const allRowsHead = [
          [
            { text: vm.$t('fertilizerReport.report_date'), alignment: 'right', style: 'td' },
            { text: ':', alignment: 'center', style: 'td' },
            { text: dateFormat(search.to_date), alignment: 'left', style: 'td' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['50%', '5%', '45%'],
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        pdfContent.push({ text: vm.$t('fertilizerReport.accountMayTons'), style: 'fertilizer', alignment: 'right', bold: true })
        pdfContent.push({ text: vm.$t('fertilizerReport.godown_wise_details_report'), style: 'tableHead', decoration: 'underline' })
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
            { text: vm.$t('fertilizerReport.destination'), style: 'th', alignment: 'center' },
            { text: vm.$t('movement.transport_agent'), style: 'th', alignment: 'center' },
            { text: vm.$t('fertilizerReport.TSP'), style: 'th', alignment: 'right' },
            { text: vm.$t('fertilizerReport.challan'), style: 'th', alignment: 'center' },
            { text: vm.$t('fertilizerProcurement.shipName'), style: 'th', alignment: 'center' },
            { text: vm.$t('fertilizerReport.MOP'), style: 'th', alignment: 'right' },
            { text: vm.$t('fertilizerReport.challan'), style: 'th', alignment: 'center' },
            { text: vm.$t('fertilizerProcurement.shipName'), style: 'th', alignment: 'center' },
            { text: vm.$t('fertilizerReport.DAP'), style: 'th', alignment: 'right' },
            { text: vm.$t('fertilizerReport.challan'), style: 'th', alignment: 'center' },
            { text: vm.$t('fertilizerProcurement.shipName'), style: 'th', alignment: 'center' },
            { text: vm.$t('fertilizerReport.godown_total'), style: 'th', alignment: 'right' }
          ]
        ]
        dataArray.dataArray.map((data, index) => {
          data.agents_array.map((agent, index3) => {
            if (index3 === 0) {
              const singleRows = [
                { text: vm.$n(index + 1), rowSpan: data.agents_array.length, style: 'td', alignment: 'center' },
                { text: (i18n.locale === 'bn') ? data.name_bn : data.name, rowSpan: data.agents_array.length, style: 'td', alignment: 'center' },
                { text: (i18n.locale === 'bn') ? agent.company_agent_name_bn : agent.company_agent_name, style: 'td', alignment: 'center' }
              ]
              agent.fertilizer_array.map((fertilizer) => {
                singleRows.push(
                  { text: vm.$n(fertilizer.total_amount), style: 'td', alignment: 'right' },
                  { text: (fertilizer.movement_info.length) ? getAllMovement(fertilizer.movement_info) : '', style: 'td', alignment: 'center' },
                  { text: (fertilizer.ships.length) ? getAllShip(fertilizer.ships) : '', style: 'td', alignment: 'center' }
                )
              })
              const lastRow = { text: vm.$n(data.warehouse_total), rowSpan: data.agents_array.length, style: 'td', alignment: 'right' }
              singleRows.push(
                lastRow
              )
              allRows.push(singleRows)
            } else {
              const singleRows = [
                {},
                {},
                { text: (i18n.locale === 'bn') ? agent.company_agent_name_bn : agent.company_agent_name, style: 'td' }
              ]
              agent.fertilizer_array.map((fertilizer) => {
                singleRows.push(
                  { text: vm.$n(fertilizer.total_amount), style: 'td', alignment: 'right', bold: true },
                  { text: (fertilizer.movement_info.length) ? getAllMovement(fertilizer.movement_info) : '', style: 'td', alignment: 'center', bold: true },
                  { text: (fertilizer.ships.length) ? getAllShip(fertilizer.ships) : '', style: 'td', alignment: 'center', bold: true }
                )
              })
              singleRows.push(
                {}
              )
              allRows.push(singleRows)
            }
          })
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['6%', '7%', '9%', '8%', '8%', '8%', '8%', '8%', '8%', '7%', '8%', '8%', '8%'],
            body: allRows
          }
        })
        pdfContent.push({ text: vm.$t('fertilizerReport.ship_godown_wise_summary_report'), style: 'tableHead', decoration: 'underline' })
        const allRowsOne = [
            [
              { text: vm.$t('fertilizerProcurement.shipName'), style: 'th', alignment: 'center' },
              { text: vm.$t('fertilizerReport.godown'), style: 'th', alignment: 'center' },
              { text: vm.$t('fertilizerReport.godown_total'), style: 'th', alignment: 'right' },
              { text: vm.$t('fertilizerReport.ship_total'), style: 'th', alignment: 'right' }
            ]
          ]

          dataArray.summary_fertilizer_array.map((sumFer) => {
            allRowsOne.push([
                { text: vm.$t('movement.fertilizer_name') + ' : ' + (i18n === 'bn') ? sumFer.fertilizer_name_bn : sumFer.fertilizer_name, colSpan: 4, style: 'td', alignment: 'center' },
                {},
                {},
                {}
              ]
            )
            sumFer.summary_ships_array.map((ship) => {
              ship.movement_info.map((movement, index) => {
                const rowHere = []
                if (index === 0) {
                  rowHere.push({ text: (i18n.locale === 'bn') ? ship.ship_name_bn : ship.ship_name, style: 'td', rowSpan: ship.movement_info.length, alignment: 'center' })
                } else {
                  rowHere.push({})
                }
                rowHere.push(
                  { text: (i18n.locale === 'bn') ? movement.name_bn : movement.name, style: 'td', alignment: 'center' },
                  { text: vm.$n(movement.total_allocated_amount), style: 'td', alignment: 'right' }
                )
                if (index === 0) {
                  rowHere.push({ text: vm.$n(ship.ship_amount), style: 'td', rowSpan: ship.movement_info.length, alignment: 'right' })
                } else {
                  rowHere.push({})
                }
                allRowsOne.push(rowHere)
              })
              const RegList = [
                { text: vm.$t('fertilizerReport.total'), style: 'th', colSpan: 3, alignment: 'right', bold: true },
                {},
                {},
                { text: vm.$n(calculateTotal(ship.movement_info)), style: 'th', alignment: 'right', bold: true }
              ]
              allRowsOne.push(RegList)
            })
          })
          const totalList = [
            { text: vm.$t('fertilizerReport.grand_total'), style: 'th', colSpan: 3, alignment: 'right', bold: true },
            {},
            {},
            { text: vm.$n(calculateTotalAll(dataArray.summary_fertilizer_array)), style: 'th', alignment: 'right', bold: true }
          ]
          allRowsOne.push(totalList)
          pdfContent.push({
            table: {
              headerRows: 1,
              widths: '15%',
              body: allRowsOne
            }
          })
        pdfContent.push({ text: vm.$t('fertilizerReport.transport_agent_wise_summary_report'), style: 'tableHead', decoration: 'underline' })
        const allRowsTwo = []
        const singleHead = [
          { text: vm.$t('movement.transport_agent'), style: 'th', alignment: 'center' },
          { text: vm.$t('fertilizerReport.destination'), style: 'th', alignment: 'center' }
        ]
        dataArray.summary_fertilizers.map((summaryFer) => {
          singleHead.push(
            { text: (i18n.locale === 'bn') ? summaryFer.fertilizer_name_bn : summaryFer.fertilizer_name, style: 'th', alignment: 'center' }
          )
        })
        singleHead.push(
          { text: vm.$t('fertilizerReport.agent_total'), style: 'th', alignment: 'right' },
          { text: vm.$t('fertilizerReport.given_target_mton'), style: 'th', alignment: 'right' },
          { text: vm.$t('fertilizerReport.achievement'), style: 'th' }
        )
        allRowsTwo.push(singleHead)
        dataArray.agent_array.map((agent) => {
          agent.agent_warehouses.map((warehouse, index) => {
              const singleRowsTwo = []
              if (index === 0) {
                singleRowsTwo.push(
                  { text: (i18n.locale === 'bn') ? agent.company_agent_name_bn : agent.company_agent_name, style: 'td', rowSpan: agent.agent_warehouses.length, alignment: 'center' }
                )
              } else {
                singleRowsTwo.push({})
              }
              singleRowsTwo.push([
                { text: (i18n.locale === 'bn') ? warehouse.name_bn : warehouse.name, rowSpan: agent.agent_warehouses.length, style: 'td', alignment: 'center' }
              ])
              warehouse.transport_fertilizers.map((fertilizer) => {
                  singleRowsTwo.push(
                      { text: vm.$n(fertilizer.total_allocated_amount), style: 'td', alignment: 'right' }
                  )
              })
              if (index === 0) {
                singleRowsTwo.push(
                    { text: vm.$n(calculateWatehouse(agent.agent_warehouses)), rowSpan: agent.agent_warehouses.length, style: 'td', alignment: 'right' },
                    { text: '', rowSpan: agent.agent_warehouses.length, style: 'td', alignment: 'right' },
                    { text: '', rowSpan: agent.agent_warehouses.length, style: 'td', alignment: 'right' }
                )
              } else {
                singleRowsTwo.push({})
                singleRowsTwo.push({})
                singleRowsTwo.push({})
              }
              allRowsTwo.push(singleRowsTwo)
          })
        })
        pdfContent.push({
          table: {
            headerRows: '',
            widths: 'auto',
            body: allRowsTwo
          }
        })
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'landscape',
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 10]
          },
          tableHead: {
            fontSize: 10,
            margin: [0, 10, 0, 10]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          fertilizer: {
            margin: [0, 7, 0, -10]
          },
          report_type: {
            fontSize: 9,
            margin: [0, 2, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('fertilizer-movement-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
