<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('fertilizerReport.movement_report') }}</h4>
        </template>
      <template v-slot:body>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset">
            <b-row>
                <b-col lg="6" sm="12">
                <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="office_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                    {{ $t('org_pro.office') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.office_id"
                      :options="officeList"
                      id="office_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
               <b-col xl="6" lg="6" md="6" sm="12" xs="12">
              <ValidationProvider name="Report Date" vid="to_date" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="to_date"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                  {{ $t('fertilizerReport.report_date') }} <span class="text-danger">*</span>
                  </template>
                    <b-form-input
                      class="fromDate"
                      v-model="search.to_date"
                      :placeholder="$t('globalTrans.select_date')"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="12">
                  <b-button type="submit" variant="primary float-right">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            </b-row>
           </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
      <b-row >
        <b-col md="12" v-if="showData">
          <b-overlay :show='loading'>
            <iq-card v-if='datas.dataArray'>
              <template v-slot:body>
                <b-row>
                  <b-col md="12">
                    <iq-card>
                      <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('fertilizerReport.movement_report') }}</h4>
                      </template>
                      <template v-slot:headerAction>
                        <b-button class="btn btn_add_new" @click="pdfExport">
                          <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                        </b-button>
                      </template>
                      <template v-slot:body>
                        <b-overlay>
                          <b-row mt-5>
                            <b-col md="12" class="table-responsive">
                              <div style="border: 2px solid;margin:10px;padding:10px">
                                <b-row>
                                  <b-col>
                                    <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                                      <u>{{  (i18 === 'bn') ? getOfficeName(search.office_id) + ' ' + $t('fertilizerReport.fertilizer_movement_report') : $t('fertilizerReport.fertilizer_movement_report') + ' ' + getOfficeName(search.office_id) }}</u>
                                    </list-report-head>
                                    <div class="text-center">
                                      <table style="width:100%;color:black;">
                                        <tr>
                                          <td align="right" style="width:40%">{{ $t('fertilizerReport.report_date') }}</td>
                                          <td align="center" style="width:5%">:</td>
                                          <td align="left" style="width:45%">{{ search.to_date | dateFormat }}</td>
                                        </tr>
                                      </table>
                                    </div>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <div class="col text-right">
                                    <span class="text-right text-dark"> {{ $t('fertilizerReport.accountMayTons') }}</span>
                                  </div>
                                </b-row>
                                <b-row>
                                  <b-col>
                                    <div class="text-left mt-3">
                                      <p class="text-dark" style="font-weight:bold">{{ $t('fertilizerReport.godown_wise_details_report') }}</p>
                                    </div>
                                    <b-table-simple class="tg" style="font-size: 11px" hover bordered small caption-top>
                                      <b-thead>
                                        <b-tr>
                                          <b-th class="tg-0lax">{{ $t('globalTrans.sl_no') }}</b-th>
                                          <b-th class="tg-0lax">{{ $t('fertilizerReport.destination') }} </b-th>
                                          <b-th class="tg-0lax">{{ $t('movement.transport_agent') }} </b-th>
                                          <b-th class="tg-0lax" style="text-align:right !important">{{ $t('fertilizerReport.TSP') }} </b-th>
                                          <b-th class="tg-0pky">{{ $t('fertilizerReport.challan') }}</b-th>
                                          <b-th class="tg-0lax">{{ $t('fertilizerProcurement.shipName') }}</b-th>
                                          <b-th class="tg-0lax" style="text-align:right !important">{{ $t('fertilizerReport.MOP') }} </b-th>
                                          <b-th class="tg-0pky">{{ $t('fertilizerReport.challan') }}</b-th>
                                          <b-th class="tg-0lax">{{ $t('fertilizerProcurement.shipName') }}</b-th>
                                          <b-th class="tg-0lax" style="text-align:right !important">{{ $t('fertilizerReport.DAP') }} </b-th>
                                          <b-th class="tg-0pky">{{ $t('fertilizerReport.challan') }}</b-th>
                                          <b-th class="tg-0lax">{{ $t('fertilizerProcurement.shipName') }}</b-th>
                                          <b-th class="tg-0lax" style="text-align:right !important">{{ $t('fertilizerReport.godown_total') }}</b-th>
                                        </b-tr>
                                      </b-thead>
                                      <b-tbody>
                                        <template v-for="(data, index) in datas.dataArray">
                                          <b-tr v-for="(agent, index3) in data.agents_array" :key="'first_' + index + index3">
                                            <b-td :rowspan="data.agents_array.length" v-if="index3 === 0" class="tg-0lax">{{ $n(index + 1) }}</b-td>
                                            <b-td :rowspan="data.agents_array.length" v-if="index3 === 0" class="tg-0lax">{{ i18 === 'bn' ? data.name_bn : data.name }} </b-td>
                                            <b-td class="tg-0lax">{{ i18 === 'bn' ? agent.company_agent_name_bn : agent.company_agent_name }} </b-td>
                                              <template v-for="(fertilizer, index1) in agent.fertilizer_array">
                                                <b-td class="tg-0lax" :key="'second_' + index1"  style="text-align:right !important">{{ $n(fertilizer.total_amount) }} </b-td>
                                                <b-td class="tg-0lax" :key="'third_' + index1">
                                                  <span v-if="fertilizer.movement_info.length">
                                                    {{ getAllMovement(fertilizer.movement_info) }}
                                                  </span>
                                                  <span v-else></span>
                                                </b-td>
                                                <b-td class="tg-0lax" :key="'fourth_' + index1">
                                                  <span v-if="fertilizer.ships.length">
                                                    {{ getAllShip(fertilizer.ships) }}
                                                  </span>
                                                  <span v-else></span>
                                                </b-td>
                                              </template>
                                            <b-td v-if="index3 === 0" :rowspan="data.agents_array.length" class="tg-0lax"  style="text-align:right !important">{{ $n(data.warehouse_total) }}</b-td>
                                          </b-tr>
                                        </template>
                                      </b-tbody>
                                    </b-table-simple>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col>
                                    <div class="text-left mt-3">
                                      <p class="text-dark" style="font-weight:bold">{{ $t('fertilizerReport.ship_godown_wise_summary_report') }}</p>
                                    </div>
                                    <b-table-simple class="tg" style="font-size: 11px" hover bordered small caption-top>
                                      <b-thead>
                                        <b-tr>
                                          <b-th class="tg-0lax">{{$t('fertilizerProcurement.shipName')}}</b-th>
                                          <b-th class="tg-0lax">{{$t('fertilizerReport.godown')}} </b-th>
                                          <b-th class="tg-0lax" style="text-align:right !important">{{$t('fertilizerReport.godown_total') }}</b-th>
                                          <b-th class="tg-0lax" style="text-align:right !important">{{$t('fertilizerReport.ship_total') }}</b-th>
                                        </b-tr>
                                      </b-thead>
                                      <b-tbody>
                                        <template v-for="(sum_fer, index) in datas.summary_fertilizer_array">
                                          <b-tr :key="'fertilizer_name' + sum_fer.fertilizer_id">
                                            <b-th colspan="4">{{ $t('movement.fertilizer_name') }} : {{  (i18 === 'bn') ? sum_fer.fertilizer_name_bn : sum_fer.fertilizer_name }}</b-th>
                                          </b-tr>
                                          <template v-for="(ship, key) in sum_fer.summary_ships_array">
                                            <b-tr v-for="(movement, index1) in ship.movement_info" :key="index + '_' + index1">
                                              <b-td v-if='index1 === 0' :rowspan="ship.movement_info.length">{{  (i18 === 'bn') ? ship.ship_name_bn : ship.ship_name }}</b-td>
                                              <b-td>{{ (i18 === 'bn') ? movement.name_bn : movement.name }}</b-td>
                                              <b-td style="text-align:right !important">{{ $n(movement.total_allocated_amount) }}</b-td>
                                              <b-td style="text-align:right !important" v-if='index1 === 0' :rowspan="ship.movement_info.length">{{ $n(ship.ship_amount) }}</b-td>
                                            </b-tr>
                                            <b-tr :key="'ship_' + key" class='row-active'>
                                              <b-th colspan="3"  style="text-align:right !important">{{ $t('fertilizerReport.total') }}</b-th>
                                              <b-th  style="text-align:right !important"> {{ $n(calculateTotal(ship.movement_info)) }}</b-th>
                                            </b-tr>
                                          </template>
                                        </template>
                                      </b-tbody>
                                      <b-tfoot>
                                        <b-tr class='row-active'>
                                          <b-th colspan="3"  style="text-align:right !important">{{ $t('fertilizerReport.grand_total') }}</b-th>
                                          <b-th  style="text-align:right !important">  {{ $n(calculateTotalAll(datas.summary_fertilizer_array)) }}</b-th>
                                        </b-tr>
                                      </b-tfoot>
                                    </b-table-simple>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col>
                                    <div class="text-left mt-3">
                                      <p class="text-dark" style="font-weight:bold">{{ $t('fertilizerReport.transport_agent_wise_summary_report') }}</p>
                                    </div>
                                    <b-table-simple class="tg" style="font-size: 11px" hover bordered small caption-top>
                                      <b-thead>
                                        <b-tr>
                                          <b-th class="tg-0lax">{{$t('movement.transport_agent')}} </b-th>
                                          <b-th class="tg-0lax">{{$t('fertilizerReport.destination')}} </b-th>
                                          <b-th v-for="(summary_fer, index) in datas.summary_fertilizers" :key="index" class="tg-0lax" style="text-align:right !important">{{ (i18 === 'bn') ? summary_fer.fertilizer_name_bn : summary_fer.fertilizer_name }} </b-th>
                                          <b-th class="tg-0lax" style="text-align:right !important">{{$t('fertilizerReport.agent_total') }}</b-th>
                                          <b-th class="tg-0lax" style="text-align:right !important">{{$t('fertilizerReport.given_target_mton') }}</b-th>
                                          <b-th class="tg-0lax">{{$t('fertilizerReport.achievement') }}</b-th>
                                        </b-tr>
                                      </b-thead>
                                      <b-tbody>
                                        <template v-for="agent in datas.agent_array">
                                          <template v-for="(warehouse, index) in agent.agent_warehouses">
                                            <b-tr :key="'last' + warehouse.godown_infos_id">
                                              <b-th v-if='index === 0' :rowspan="agent.agent_warehouses.length">{{  (i18 === 'bn') ? agent.company_agent_name_bn : agent.company_agent_name }}</b-th>
                                              <b-td>{{  (i18 === 'bn') ? warehouse.name_bn : warehouse.name }}</b-td>
                                              <b-td v-for="(fertilizer, index1) in warehouse.transport_fertilizers" :key="index1"  style="text-align:right !important">{{ $n(fertilizer.total_allocated_amount) }}</b-td>
                                              <b-td v-if='index === 0' :rowspan="agent.agent_warehouses.length"  style="text-align:right !important">{{ $n(calculateWatehouse(agent.agent_warehouses)) }}</b-td>
                                              <b-td v-if='index === 0' :rowspan="agent.agent_warehouses.length"></b-td>
                                              <b-td v-if='index === 0' :rowspan="agent.agent_warehouses.length"></b-td>
                                            </b-tr>
                                          </template>
                                        </template>
                                      </b-tbody>
                                    </b-table-simple>
                                  </b-col>
                                </b-row>
                              </div>
                            </b-col>
                          </b-row>
                        </b-overlay>
                      </template>
                    </iq-card>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
            <div class="panel-body text-center mt-3" v-else>
              <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
  </b-container>
</template>
<style type="text/css">
.tg  {border-collapse:collapse;border-spacing:0;}
.tg td{text-align: center !important; vertical-align: middle !important;}
.tg th{text-align: center !important; vertical-align: middle !important;}
</style>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, movementReport } from '../../api/routes'
import ListReportHead from '.././ListReportHead.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ExportPdf from './export-pdf_details'
import flatpickr from 'flatpickr'

export default {
  name: 'UiDataTable',
  components: {
    ListReportHead,
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      showData: false,
      search: {
        to_date: '',
        office_name: '',
        office_id: 0
      },
      datas: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList
    }
  },
  computed: {
    transportAgentList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.transportAgentList.filter(item => item.status === 1)
    },
    officeList: function () {
      return this.$store.state.commonObj.officeList.filter(item => item.office_type_id === 72)
    },
    i18 () {
      return this.$i18n.locale
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
    core.index()
  },
  methods: {
    getAllMovement (movementInfo) {
      return Array.prototype.map.call(movementInfo, function (item) { return item.lori_challan_no }).join(',')
    },
    getAllShip (ships) {
      const vm = this
      return Array.prototype.map.call(ships, function (item) {
        if (vm.i18 === 'bn') {
          return item.ship_name_bn
        } else {
          return item.ship_name
        }
      }).join(',')
    },
    calculateWatehouse (agent) {
       return agent.map(r => r.warehouse_total).reduce((a, c) => {
        a = a ? parseInt(a) : 0
        c = c ? parseInt(c) : 0
        return a + c
      })
    },
    calculateTotal (report) {
       return report.map(r => r.total_allocated_amount).reduce((a, c) => {
        a = a ? parseInt(a) : 0
        c = c ? parseInt(c) : 0
        return a + c
      })
    },
    calculateTotalAll (data) {
      let total = 0
      data.forEach((element, key) => {
        element.summary_ships_array.forEach((element1, key1) => {
          element1.movement_info.forEach((element2, key2) => {
            total += element2.total_allocated_amount
          })
        })
      })
      return total
    },
    async register () {
      this.showData = true
      this.loadData()
    },
    async loadData () {
      const office = this.$store.state.commonObj.officeList.find(item => item.value === parseInt(this.search.office_id))
      this.search.office_name = office !== undefined ? office.text_en : ''
      this.search.office_name_bn = office !== undefined ? office.text_bn : ''
      this.loading = true
        await RestApi.getData(seedFertilizerServiceBaseUrl, movementReport, this.search).then(response => {
        if (response.success) {
          if (response.data) {
            this.datas = response.data
            this.showData = true
          }
        } else {
          this.datas = []
        }
      })
      this.loading = false
    },
    pdfExport () {
      const office = this.search.office_id ? this.getOfficeName(this.search.office_id) : ''
      const repotDate = this.search.to_date
      const reportTitle = (this.i18 === 'bn') ? this.getOfficeName(this.search.office_id) + ' ' + this.$t('fertilizerReport.fertilizer_movement_report') : this.$t('fertilizerReport.fertilizer_movement_report') + ' ' + this.getOfficeName(this.search.office_id)
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.datas, this, office, repotDate, this.search)
    },
    getOfficeName (Id) {
      const office = this.$store.state.commonObj.officeList.find(item => item.value === parseInt(Id))
      if (typeof office === 'undefined') {
        return ''
      } else {
        return office.text
      }
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
 .row-active {
    color: #212529;
    /* background-color: rgba(0, 0, 0, 0.075); */
    border: 1px solid black !important;
 }
</style>
